// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registrar-js": () => import("./../../../src/pages/registrar.js" /* webpackChunkName: "component---src-pages-registrar-js" */),
  "component---src-templates-all-js": () => import("./../../../src/templates/all.js" /* webpackChunkName: "component---src-templates-all-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-store-js": () => import("./../../../src/templates/store.js" /* webpackChunkName: "component---src-templates-store-js" */)
}

